.ClientWelcomePage {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  >div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    padding-bottom: 120px;
  }
}

.logo {
  width: 100%;
  height: 150px;
  background: top center no-repeat url('hamlyns-logo.svg');
  background-size: auto 105px;
  padding-top: 120px;
  text-align: center;
  font-size: 16px;
}