.DocumentDropzone {
  width: 100%;
  min-height: 240px;

  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.15);
  border-style: dashed;
  background-color: white;
  outline: none;
  transition: border 0.2s ease-in-out;
  cursor: pointer;
  font-size: 16px;

  p {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  >div {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 15px;

    svg {
      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }
  }
}

.fluid {
  height: calc(100% - 40px);
}

.active {
  border-color: #00b0f0;
}