@import "../../../styles/colors.scss";
@import "../../../styles/sizes.scss";

.PdfViewer {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: $border-radius-default;
}

.IosPdfViewer {
  * {
    user-select: none !important;
  }

  nav {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    gap: 10px;
    padding: 0 5px 0 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    :first-child {
      flex: 1;
    }
  }
}

.canvasDisplay {
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;
  background-color: #eee;
  text-align: center;
  padding: 10px;

  canvas {
    width: 100%;
    height: auto !important;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
    max-width: calc(100% - 20px);
    aspect-ratio: 210 / 297; // We're assuming it's A4
    background-color: #fff;
  }
}