@import "../../../styles/colors.scss";
@import "../../../styles/sizes.scss";

.Header {
  background-color: #fff;
  width: 100%;
  height: 60px;
  min-height: 60px;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.075);

  @media print {
    display: none;
  }

  >nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    height: 100%;

    // Display logo at right of header
    :global(.logo) {

      background: right center no-repeat url('hamlyns-logo.svg');
      background-size: auto 40px;
      width: 100%;
      height: 100%;
      margin-right: 10px;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 130px;

      @media only screen and (max-width: 640px) {
        a {
          display: none;
        }
      }

      @media only screen and (max-width: 600px) {
        display: none;
      }

    }

    >a {
      color: $color-link;
      font-weight: 700;
      text-decoration: none;
      text-transform: uppercase;

      height: 100%;
      padding: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      cursor: pointer;
      user-select: none;
      transition: color 0.2s linear;

      &:hover {
        color: $color-link-hover;
      }
    }
  }
}