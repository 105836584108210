@import "../../../styles/sizes.scss";

// TODO Merge this with ClientGatewayPage styles

.main {

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  h2 {
    span {
      float: right;
      height: 66px;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 10px;

      @media only screen and (max-width: $breakpoint-tablet) {
        display: block;
        float: none;
        margin-top: 20px;
        gap: 10px;

        button,
        a.button {
          width: 100%;
        }
      }

    }
  }

  h3 {
    font-size: 36px;
  }

  p {
    margin-bottom: 10px;
  }

  strong:only-child {
    display: block;
    margin-top: 15px;
  }

  article,
  section {
    margin-bottom: 20px;
  }

}