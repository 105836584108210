@import "../../../styles/sizes.scss";

.Subheader {
  width: 100%;
  height: 150px;

  @media only screen and (max-width: $breakpoint-tablet) {
    height: auto;
  }

  >div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 100%;
    gap: 15px;

    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
      justify-content: flex-start;
      margin: 30px 0;
    }

    >div:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      h1 {
        @media only screen and (max-width: $breakpoint-tablet) {
          margin: 0;
        }

        select {
          font: inherit;
          padding: 0 15px 0 0;
          margin: 0;
          width: auto;
          height: auto;
          border: none;
          background-color: transparent;

          option {
            font-size: initial;
          }
        }
      }

      label {
        flex: 1;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        >input[type="checkbox"] {
          margin-right: 5px;
        }
      }
    }

    >div:last-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      p {
        font-size: 20px;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

}