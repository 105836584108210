.Loader {
  width: 72px;
  height: 72px;
  border: 6px solid #eee;
  border-bottom-color: #0c2950;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &:global(.small) {
    width: 36px;
    height: 36px;
    border-width: 4px;
  }

  &:global(.medium) {
    width: 48px;
    height: 48px;
    border-width: 5px;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}