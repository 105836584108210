@import "../../../styles/sizes.scss";

// TODO Merge this with GuestProductPage styles

.main {

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 40px;

    h2 {
      flex: 1;
      margin-bottom: 0;
    }

    div {
      min-width: 120px;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;

      >* {
        width: 100%;
      }
    }
  }

  h3 {
    font-size: 36px;
  }

  p {
    margin-bottom: 10px;
  }

  a {
    font-weight: inherit;
    text-decoration: underline;
  }

  article,
  section {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: $breakpoint-tablet) {

    button,
    a.button {
      width: 100%;
    }

  }

  @media print {

    button,
    a.button {
      display: none;
    }

  }

}