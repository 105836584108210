@import "../../../styles/sizes.scss";

.ClientDashboardPage {

  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;

  @media only screen and (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }

}