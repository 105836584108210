@import "../../../styles/colors.scss";
@import "../../../styles/sizes.scss";

.ClientProfilesPage {

  h2 {
    text-align: center;
  }

  main {
    text-align: center;
  }

}

.icon {
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  padding: 20px;

  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-content: stretch;
    align-items: center;
    width: 240px;
    gap: 10px;

    div {
      &:first-of-type {
        width: 160px;
        height: 160px;
        background-color: $color-button-secondary;

        // TODO Hover state
      }

      &:last-child {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
      }
    }

  }
}