@import "../../../styles/sizes.scss";

.AdminReportingPage {

  table {

    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    margin-bottom: 20px;

    thead {
      position: sticky;
      top: 0;
      background-color: white;
    }

    tr {
      &:nth-child(even) {
        background-color: white;
      }

      &:global(.is-admin) {
        td {
          background-color: rgba(232, 244, 255, 0.4);
        }
      }
    }

    th,
    td {
      text-align: left;
      padding: 10px 20px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;

      &:nth-child(6) {
        text-align: right;
      }
    }

    th {
      font-weight: 700;
      white-space: nowrap;

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(6) {
        width: 10%;
      }
    }

    td {
      &[colspan] {
        text-align: center;
        user-select: none;
        font-weight: 400;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(6) {
        white-space: nowrap;
      }

      &:nth-child(5) {
        white-space: break-word;
      }
    }

  }

}

// TODO Merge this with AdminClientsPage
.titleBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex: 1;
    width: 100%;

    h2 {
      flex: 1;
    }
  }

  >input[type=text] {
    margin-left: 10px;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    gap: 0;
    margin-bottom: 20px;

    >input[type=text] {
      width: 100%;
      margin-left: 0;
    }
  }
}