@import "../../../styles/sizes.scss";

.ClientChecklistPage {

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  h2 {
    span {
      float: right;
      height: 66px;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 10px;

      @media only screen and (max-width: $breakpoint-tablet) {
        display: block;
        float: none;
        margin-top: 20px;
        gap: 10px;

        a.button {
          width: 100%;
        }
      }

    }
  }

  iframe,
  object {
    width: 100%;
    height: 100%;
    background-color: white;
  }

}