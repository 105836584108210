@import "../../../styles/sizes.scss";

.DropdownMenu {

  position: relative;
  display: inline-block;

  :global(.dropdown-content) {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    min-width: 100px;
    z-index: 1;

    @media only screen and (max-width: $breakpoint-mobile) {
      right: 0;
    }
  }

  &:hover :global(.dropdown-content) {
    display: block;
  }

}

.DropdownMenuItem {
  width: 100%;
  height: 44px;
  display: block;
  line-height: 44px;
  padding: 0 10px;
  white-space: nowrap;
  cursor: pointer;
  min-width: 100%;

  label {
    cursor: inherit;
  }

  input {
    vertical-align: middle;
    margin-right: 5px;
  }
}