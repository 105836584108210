@import "../../../styles/sizes.scss";

.AdminClientsPage {

  label {
    input[type=file] {
      display: none;
    }
  }

}

.titleBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex: 1;
    width: 100%;

    h2 {
      flex: 1;
    }
  }

  >input[type=text] {
    margin-left: 10px;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    gap: 0;
    margin-bottom: 20px;

    >input[type=text] {
      width: 100%;
      margin-left: 0;
    }
  }
}

.clientList {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;

  >p {
    text-align: center;
  }
}

.client {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 20px;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 5px;

  div:first-child {
    flex: 1;
    overflow: hidden;

    h4 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      color: inherit;

      svg {
        vertical-align: bottom;
      }
    }

    p {
      font-size: 10px;
      opacity: 0.5;
      color: inherit;
    }
  }

  button,
  :global(.button) {
    width: 240px;
    white-space: nowrap;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    height: auto;
    flex-direction: column;
    padding: 10px;

    div:first-child {
      margin-bottom: 5px;

      h4,
      p {
        text-align: center;
      }
    }

    button,
    :global(.button) {
      width: 100%;
    }

  }

}

.badge {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 8px;
  font-weight: 700;
  width: 12px;
  height: 12px;
  text-align: center;
  line-height: 10px;
  background-color: #0c2950;
  border: 1px solid white;
  border-radius: 50%;
  color: white;
}