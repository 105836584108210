.DefaultLayout {

  display: flex;
  flex-direction: column;
  width: 100vw;

}

.contents {

  display: flex;
  flex-direction: column;

  >div:last-of-type {
    position: relative;
  }

}