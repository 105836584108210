@import "../../../styles/colors.scss";
@import "../../../styles/sizes.scss";

.CardLink {

  background-color: $color-button-secondary;
  color: white;
  border-radius: $border-radius-default;
  width: 320px;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  padding: 25px 30px;
  cursor: pointer;
  transition: background-color 0.2s linear;

  &:hover {
    background-color: $color-button-secondary-hover;
  }

  p {

    margin: 0;
    padding: 0;

    &:first-of-type {
      font-size: 24px;
      font-weight: 400;
    }

    &:not(:first-of-type) {
      line-height: 24px;
      min-height: 48px;
    }

  }

  @media only screen and (max-width: $breakpoint-tablet) {
    width: auto;
    height: 240px;
  }

}

.Link {
  color: inherit;
  text-decoration: none;
}