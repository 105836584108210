@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import "./styles/colors.scss";
@import "./styles/sizes.scss";

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: #fafafa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Roboto", "Open Sans", sans-serif;
  font-weight: 300;
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

p,
li {
  font-size: 16px;
}

h1,
h2,
h3,
h4 {
  color: #1d2127;
  text-align: left;
}

h1 {
  font-size: 48px;
  text-align: left;
  margin: 40px 0;

  @media only screen and (max-width: $breakpoint-tablet) {
    font-size: 36px;
    margin: 30px 0;
  }
}

h2 {
  font-size: 64px;
  margin-bottom: 40px;

  @media only screen and (max-width: $breakpoint-tablet) {
    font-size: 48px;
    margin-bottom: 30px;
    text-align: center;
  }
}

h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

h4 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}

a {
  text-decoration: none;
  color: $color-link;
  font-weight: 700;
  transition: color .2s linear;

  &:hover {
    color: $color-link-hover;
  }
}

input:not(input[type=checkbox]),
.button,
button,
select {
  color: #000;
  height: 44px;
  padding: 0 20px;
  border: none;
  border-radius: $border-radius-default;
  font-size: 14px;
  font-weight: 400;
}

input:not(input[type=checkbox]) {
  background-color: white;
  border: 1px solid #ccc;
}

button,
.button {

  background-color: $color-button-primary;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s linear;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;

  &.transparent {
    background-color: transparent;
    color: black;

    &:disabled {
      background-color: transparent;
      opacity: 0.2;
    }
  }

  &.icon {
    width: 40px;
    padding: 0;

    svg:first-child {
      margin: 0;
    }
  }

  &:hover {
    background-color: $color-button-primary-hover;
  }

  &:disabled {
    background-color: rgba(12, 41, 80, 0.333);
    cursor: not-allowed;
  }

  input+svg,
  svg:first-child {
    margin: 0 6px;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

svg {
  vertical-align: middle;
}

select {
  background-color: white;
  border: 1px solid #ccc;

  option {
    background-color: white;
    color: black;
  }
}

b,
strong {
  font-weight: 400;
}

label {
  input {
    vertical-align: middle;
  }
}

.fluid {
  width: 100%;
}

.not-interactive {
  pointer-events: none;
}

.max-width {
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.padded {
  padding: 0 20px;
}

.error-message {
  color: #e00;
  font-weight: 400;
}

.not-printable {
  @media print {
    display: none;
  }
}