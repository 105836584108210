@import "../../../styles/colors.scss";
@import "../../../styles/sizes.scss";

.ClientUploadPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 16px;

  >div {
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: 40px;

    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }

    >div {
      flex: 1;
      overflow-x: hidden;
      margin-bottom: 20px;

      // File list
      &:nth-child(1) {
        flex: 3;
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 20px;
      }

      // File types
      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 240px;
        gap: 20px;
        background-color: $color-info-background;
        padding: 20px;

        ul {
          margin: 0;
          padding: 0;

          li {
            font-size: 16px;
            line-height: 30px;
            list-style-type: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            svg {
              vertical-align: middle;
              margin-bottom: 4px;
            }
          }
        }

        p {
          margin: 20px 0;
        }

        button {
          background-color: $color-button-secondary;

          &:hover {
            background-color: $color-button-secondary-hover;
          }
        }

      }
    }
  }
}

.file {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 20px;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: $border-radius-default;

  div:first-child {
    flex: 1;
    overflow: hidden;
    max-width: 100%;

    h4 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      color: inherit;
    }

    p {
      font-size: 10px;
      opacity: 0.5;
      color: inherit;
    }
  }

  div:nth-child(2) {

    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    select {
      width: 180px;
      vertical-align: middle;
      padding: 0 6px;
      text-overflow: ellipsis;
    }

  }

  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    height: auto;
    padding: 10px;

    div:first-child {

      h4,
      p {
        text-align: center;
      }

    }
  }

}

.loader {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}